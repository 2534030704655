<template>
  <b-modal
      :id="'unpublish-'+item.slug"
      :ref="'unpublish-'+item.slug"
      centered
      no-close-on-backdrop
      footer-class="flex-nowrap"
  >
    <template #modal-header>
      <h3>{{ $t('modals.unpublishItem.title') }}</h3>
    </template>
    <div class="unpublish-icon text-center px-2">
      <b-icon icon="exclamation-circle-fill" variant="warning" class="mt-5 mb-4"></b-icon>
      <div class="mt-2 mb-4" v-html="$t('modals.unpublishItem.message')"></div>
    </div>
    <template #modal-footer="{ close, ok }">
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="close()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="unpublishItem"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalUnpublishItem',
  props: ['item'],
  methods: {
    unpublishItem() {
      this.$emit('unpublish-item', this.item);
      const modalRef = `unpublish-${this.item.slug}`;
      this.$refs[modalRef].hide();
    },
  },
};
</script>

<style scoped lang="scss">
.unpublish-icon svg {
  font-size: 80px;
}
</style>
